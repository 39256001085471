.main-nav {
  position: absolute;
  z-index: 6;
  width: 100%;
  z-index: 20;

  user-select: none; /* Standard syntax */
  -webkit-user-select: none; /* Safari */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* IE/Edge */
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0); /* For Webkit browsers */
  -moz-tap-highlight-color: rgba(0, 0, 0, 0); /* For Mozilla browsers */
}

.nav-menu {
  width: 100%;
  height: 15vh;
}

.logo {
  max-height: 8vh;
}

.main-nav a {
  color: #fff;
  font-family: "Belanosima", "Berlin Sans FB", "Century Gothic", Helvetica,
    sans-serif;
  font-weight: 400;
  font-size: 1.5rem;
  padding: 0.2rem 1rem;
}

.main-nav a:hover {
  text-decoration: none;
  background-color: #fff;
  color: #000;
}

.burger-icon {
  background-color: #00000093;
}

.burger-icon:hover {
  background-color: #000;
  filter: invert(100%);
}

.burger-icon:active {
  background-color: #d9b4f399;
  filter: invert(0%);
}

.main-nav a:active {
  color: #fff;
  background-color: #d9b4f399;
}

.main-nav a:hover img,
.main-nav a:active img {
  filter: invert(100%);
}

.mobile-menu {
  flex-direction: column;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  background-color: #4e3d52d0;
  backdrop-filter: blur(10px);
  border-bottom: 1px solid black;
}

.mobile-menu div {
  text-align: center;
}

.mobile-menu img {
  max-height: 10vh;
  margin: auto;
  margin-top: 0.9rem;
  margin-bottom: 1rem;
}

a.mobile-link {
  color: #fff;
  padding: 1rem 0;
  text-align: center;
}

.burger-icon {
  color: #fff;
  font-size: 2rem;
  padding: 1rem;
  cursor: pointer;
  display: none;
}

@media screen and (max-width: 820px) {
  .mobile-menu {
    display: flex;
    position: fixed;
  }

  .desktop-link {
    display: none;
  }

  .logo-link {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
  }

  .nav-menu {
    align-items: center;
    display: flex;
    justify-content: center;
  }

  .burger-icon {
    position: fixed;
    right: 1rem;
    display: block;
    max-width: 32px;
    max-height: 26px;
  }
}

@media not screen and (max-width: 820px) {
  .nav-menu {
    display: flex;
    justify-content: space-around;
    align-items: center;
    flex-direction: row;
  }

  .desktop-link {
    height: 2rem;
  }

  .desktop-link,
  .logo-link {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .mobile-menu {
    display: none;
  }

  .main-nav .contact {
    text-decoration: none;
    background-color: #fff;
    color: #000;
  }

  .main-nav .contact:hover {
    color: #fff;
    background-color: #d9b4f399;
  }
}
