footer {
  font-size: smaller;
  width: 100%;
  text-align: center;
  padding-bottom: 2rem;
  border-top: 1px solid #00000033;
  background: linear-gradient(to top, #fefefe 50%, #dddddd);
}

footer a {
  color: #8d3abd;
}

footer a:hover {
  color: black;
}

footer .links {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-end;
  padding-top: 1rem;
}

footer .links div {
  width: 100%;
}

footer .socials {
  text-align: center;
}

footer img {
  height: 2rem;
}

footer .socials img {
  padding: 0.8rem;
  margin: 0.2rem;
  margin-top: 0;
}

footer a:hover img {
  transform: scale(1.1);
}

footer .nav {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin: auto;
  margin-bottom: 0.8rem;
}

footer .nav a {
  display: flex;
  flex-direction: column;
  padding: 0.8rem;
  color: black;
}

footer .nav a:hover {
  text-decoration: underline;
}

footer .nav img {
  margin: auto;
  filter: invert(100%);
}

footer .nav a:hover,
footer .nav a:active,
footer .nav a:focus {
  background-color: #ffffff11;
  filter: invert(100%) drop-shadow(0 0 2px);
}
