html,
body,
.container,
h1,
h2,
h3 {
  margin: 0;
  padding: 0;
  font-weight: normal;
  font-size: large;
}

html {
  scroll-behavior: smooth;
}

body {
  background: linear-gradient(to top, #eaeaea, #f0f0f0);
}

a {
  text-decoration: none;
  color: #1e2074;
}

a:hover {
  text-decoration: underline;
  color: #0c0d3b;
}

.page-not-found {
  height: calc(100vh);
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  color: ivory;
  background: url("/public/img/404.webp"),
    linear-gradient(to top, #0c0d3b, #0c0c0c);
}

.page-not-found header {
  margin-top: 4rem;
  color: yellow;
}

header {
  box-shadow: 0 1rem 6rem rgba(0, 0, 0, 0.274), 0 0 0.5rem rgba(0, 0, 0, 0.699);
}

:not(header) > h1 {
  text-shadow: 0 -2px 1.5px rgb(255, 255, 255);
  font-weight: 600;
  font-family: "Belanosima", "Berlin Sans FB", "Century Gothic", Helvetica,
    sans-serif;
  text-align: center;
  font-size: 2.5rem;
  color: #22274e;
  margin-top: 2rem;
}

:not(header) > h2 {
  font-weight: 400;
  font-family: "Belanosima", "Berlin Sans FB", "Century Gothic", Helvetica,
    sans-serif;
  text-align: center;
  font-size: 2rem;
  color: #22274e;
}

:not(header) > h2 {
  font-weight: 400;
  font-family: "Belanosima", "Berlin Sans FB", "Century Gothic", Helvetica,
    sans-serif;
  text-align: center;
  font-size: 2rem;
  color: #22274e;
}

:not(header) > h3 {
  text-align: left;
  width: 100%;
  color: #22274e;
}

ul {
  margin-top: 0;
  margin-bottom: 0;
}

header .hero-container div {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  margin: auto;
  background: linear-gradient(
    to top right,
    rgba(18, 18, 43, 0.15),
    rgba(18, 18, 43, 0.35),
    rgba(18, 18, 43, 0.5),
    rgba(18, 18, 43, 0.4),
    rgba(18, 18, 43, 0.3)
  );
}

header img.holo {
  position: relative;
  animation: holographFlicker 8s infinite ease-in-out;
  filter: drop-shadow(0 0 1rem rgba(255, 255, 255, 0.4));
}

header .hero-container .hero-head {
  text-shadow: 0 2px 0.1rem rgba(0, 0, 0, 0.15), 0 4px 0.5rem rgba(0, 0, 0, 0.3);
  color: #fcd116;
}

@keyframes holographFlicker {
  0%,
  100% {
    opacity: 0.566;
    transform: translateY(-2px);
  }
  50% {
    opacity: 0.733;
    transform: translateY(2px);
  }
}

@media screen and (max-width: 820px) {
  header .hero-container {
    display: flex;
    flex-direction: column;
    padding: 8rem 0 4rem 0;
    margin-bottom: 2rem;
  }

  header .hero-container div {
    align-items: center;
  }

  header .hero-container .hero-head {
    text-align: center;
    width: 100%;
  }

  header .hero-container .hero-p {
    text-align: center;
    margin: 0;
    padding-bottom: 2rem;
    width: 60%;
  }

  header .hero-container img {
    width: 25%;
    height: 25%;
    max-width: 10rem;
    margin: 0 auto;
  }
}

@media not screen and (max-width: 820px) {
  header .hero-container {
    display: flex;
    flex-direction: row;
    width: 80%;
    margin: auto;
    max-width: 600px;
    padding: 11rem 2rem 4rem 0;
    margin-bottom: 2rem;
    justify-content: center;
  }

  header .hero-container div {
    align-items: flex-end;
    padding: 1rem;
    margin-top: 1rem;
    border-radius: 4px;
  }

  header .hero-container .hero-p {
    text-align: right;
    margin: 0;
    width: 100%;
  }

  header .hero-container .hero-head {
    text-align: right;
    margin-top: 0;
    padding: 0;
    margin-bottom: 2rem;
  }

  header .hero-container img {
    max-width: 10rem;
    max-height: 10rem;
    margin: auto;
  }

  header img.holo {
    left: 2rem;
  }
}

.cta-button a {
  min-width: 33%;
  max-width: calc(min(80%, 600px));
  font-size: 1.2rem;
  background: linear-gradient(to top, #1e2074, #2636ad);
  color: white;
  border-radius: 2rem;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  padding: 1rem;
  margin: 2rem auto;
  box-shadow: 0 1px 2px rgb(0, 0, 0, 0.8), 0 5px 10px rgb(0, 0, 0, 0.4);
}

.cta-button a:hover,
.cta-button a:focus {
  text-decoration: none;
  background: linear-gradient(to top, #2c2e94, #3244c9);
  color: #ccc;
  box-shadow: 0 1px 2px rgb(0, 0, 0, 0.5), 0 5px 10px rgb(0, 0, 0, 0.5);
}

.cta-button a:active {
  text-decoration: none;
  background: linear-gradient(to top, #979797, #dbdbdb);
  color: #333;
  box-shadow: none;
}
